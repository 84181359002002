<template>
  <div class="main-box" style="padding: 10px 10px 40px 10px; box-sizing: border-box; " v-if="info">
    <div class="flex">
      <finance-month-template :isEdit="true" :data="content" :callFunc="changeContent" v-if="info.c_type==2"></finance-month-template>
    </div>
  </div>
</template>
<script>

import FinanceMonthTemplate from "@/components/finance/FinanceMonthTemplate";
export default {
  components: {
    FinanceMonthTemplate,
  },
  data() {
    return {
      info: false,
      id: 0,
      source_domain: this.config.SOURCE_DOMAIN,
      content: {}
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    getInfo() {
      this.$api.operate.financeDetail({id:this.id},res => {
        if(res.errcode==0) {
          this.info = res.data
          setTimeout(res => {
            this.content = this.info
          },100)
        }
      })
    },
    changeContent(data) {
      var params = {
        id: this.id,
        data: data
      }
      this.$api.operate.financeFinish(params, res => {
        if(res.errcode==0) {
          // this.success(res.errmsg)
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
</style>
