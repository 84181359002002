<template>
  <div class="box">
    <el-form label-position="left" :rules="rules" ref="ruleForm" label-width="40px" size="small">
      <el-form-item label="" prop="title">
        <div style="max-height: 500px; overflow-y: scroll">
          <el-tree
              ref="tree"
              :data="treeInfo"
              show-checkbox
              node-key="id"
              :check-strictly="true"
              :default-expand-all="true"
              :default-checked-keys="defaultCheckedKeys"
              :props="defaultProps">
          </el-tree>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      treeInfo: [],
      defaultCheckedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      rules: {
      }
    };
  },
  created() {
    this.getTreeInfo()
  },
  mounted() {
  },
  computed: {
  },
  props: {
    selParams: Object
  },
  watch: {
    'selParams': {
      handler (newValue, oldValue) {
        setTimeout(res => {
          this.$refs.tree.setCheckedKeys([])
          if(newValue.goods) {
            var defaultCheckedKeys = []
            newValue.goods.forEach(item => {
              defaultCheckedKeys.push(item.goods_id)
            })
            this.defaultCheckedKeys = defaultCheckedKeys
          }
        },100)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getTreeInfo() {
      if(this.treeInfo.length>0) {
        return false
      }
      this.$api.operate.goodsTree({},res => {
        if(res.errcode==0) {
          var treeInfo = res.data
          treeInfo.forEach(item => {
            item.disabled = true
            return item
          })
          this.treeInfo = treeInfo
        }
      })
    },
    submitForm(formName) {
      var _this = this
      // console.log(this.$refs.tree.getCheckedNodes())
      _this.$emit('callFunc',this.$refs.tree.getCheckedNodes());
    },
  }
};
</script>
<style scoped>
</style>
