<template>
  <div class="box" style="padding: 0; margin: 0;" v-if="info">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-select v-model="search.audit_status" clearable placeholder="审计状态" size="small">
          <el-option key="1" label="未审计" value="1"></el-option>
          <el-option key="2" label="已审计" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.status_color" clearable placeholder="请选择状态" size="small">
          <el-option key="1" label="已提车未付款" value="1"></el-option>
          <el-option key="2" label="已提车已付款" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.follow_people" clearable placeholder="请选择业务跟进" size="small">
          <el-option
              v-for="item in info.staff_list"
              :key="item.id"
              :label="item.nickname"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.source_people" clearable placeholder="请选择客户来源" size="small">
          <el-option
              v-for="item in info.source_list"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.pay_type" clearable placeholder="请选择收款方式" size="small">
          <el-option
              v-for="item in info.paytype_list"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">筛选</el-button>
      </el-form-item>
    </el-form>
    <table width="100%" class="table-xls tc bg0">
      <tr>
        <td :colspan="colspan" class="h2 tc black">每日财务登记表（{{info.start_date.substr(0,7)}}）</td>
      </tr>
    </table>
    <div class="box" style="padding: 0; margin: 0; width: 100%; overflow-x: auto; margin-top: -1px">
    <table :width="colspan*120 + 'px'" class="table-box tc">
      <tr>
        <td width="100" class="bg5">审计</td>
        <td width="200" class="bg5">设置</td>
        <td width="200" class="bg5">日期</td>
        <td width="200" class="bg5">交车时间</td>
        <td width="200" class="bg5">业务跟进</td>
        <td width="200" class="bg5">客户来源</td>
        <td width="200" class="bg5">是否第一次</td>
        <td width="200" class="bg5">车牌/临牌</td>
        <td width="200" class="bg5">车型</td>
        <td width="200" class="bg5">车身颜色</td>
        <td width="400" class="bg5">产品</td>
<!--        <td width="200" v-for="(item,index) in info.menu_list" :key="'m_' + index" class="bg5">{{ item.title }}</td>-->
        <td width="200" class="bg5">引导大众点评</td>
        <td width="200" class="bg5">精品</td>
        <td width="200" class="bg5">精品细分/及其它备注</td>
        <td width="200" class="bg5">总价</td>
        <td width="200" class="bg5">收款方式</td>
        <td width="200" class="bg5">状态</td>
        <td width="200" class="bg5">备注</td>
      </tr>
      <tr v-for="(item,index) in content" :key="'s_' + index" :class="{'bg-blue': item.status_color==2,'bg-orange': item.status_color==1,'none':!item.isShow}">
        <td class="no-border">
          <el-popconfirm v-if="item.audit_status==0"
              title="确认无误吗，操作后不能更改？"
              @confirm="auditItem(index)"
          >
            <el-button type="text" slot="reference" size="small">审计</el-button>
          </el-popconfirm>
          <div v-else>已审计</div>
        </td>
        <td class="no-border">
          <el-select v-model="item.status_color" placeholder="点击设置" size="small" @change="saveLine(index)" clearable style="width: 130px" :disabled="item.audit_status==1">
            <el-option key="1" label="已提车未付款" value="1"></el-option>
            <el-option key="2" label="已提车已付款" value="2"></el-option>
          </el-select>
        </td>
        <td class="no-border">
          <el-date-picker @change="saveLine(index)" :disabled="item.audit_status==1"
              v-model="item.c_date"
              type="date"
              format="MM月dd日"
              size="small"
              placeholder="">
          </el-date-picker>
        </td>
        <td class="no-border">
          <el-date-picker @change="saveLine(index)" :disabled="item.audit_status==1"
              v-model="item.handover_date"
              type="date"
              format="MM月dd日"
              size="small"
              placeholder="">
          </el-date-picker>
        </td>
        <td class="no-border">
          <el-select v-model="item.follow_people" placeholder="" size="small" @change="saveLine(index)" :disabled="item.audit_status==1">
            <el-option
                v-for="item in info.staff_list"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
            </el-option>
          </el-select>
        </td>
        <td class="no-border">
          <el-select v-model="item.source_people" placeholder="" size="small" @change="saveLine(index)" clearable :disabled="item.audit_status==1">
            <el-option
                v-for="item in info.source_list"
                :key="item.id"
                :label="item.title"
                :value="item.id">
            </el-option>
          </el-select>
        </td>
        <td class="no-border">
          <el-select v-model="item.is_first" placeholder="" size="small" @change="saveLine(index)" clearable :disabled="item.audit_status==1">
            <el-option key="1" label="是" value="1"></el-option>
            <el-option key="2" label="否" value="2"></el-option>
          </el-select>
        </td>
        <td><input type="text" placeholder="" class="input tc" v-model="item.car_no" @change="saveLine(index)" :disabled="item.audit_status==1" /></td>
        <td class="no-border">
          <el-select v-model="item.car_model_id" placeholder="" size="small" @change="saveLine(index)" :disabled="item.audit_status==1">
            <el-option
                v-for="item in info.car_model_list"
                :key="item.id"
                :label="item.title"
                :value="item.id">
            </el-option>
          </el-select>
        </td>
        <td><input type="text" placeholder="" class="input tc" v-model="item.car_color" @change="saveLine(index)" :disabled="item.audit_status==1" /></td>
        <td @click="selectGoods(index)" style="text-align: left">
          <span v-for="(child,cindex) in item.item" :key="cindex"><span v-if="cindex>0">、</span>{{child.title}}</span>
        </td>
<!--        <td v-for="(child,cindex) in info.menu_list" :key="'t_' + index + '_' + cindex" class="bg0" @click="selectGoods(index,cindex)">-->
<!--          <div v-for="(ct,ctidx) in item.item" :key="ctidx">-->
<!--            <div v-if="ct.menu_id==child.id">{{ct.title}} <i class="el-icon-delete" @click.stop="delGoods(index,ctidx)"></i></div>-->
<!--          </div>-->
<!--        </td>-->
        <td><input type="text" placeholder="" class="input tc" v-model="item.comment_1" @change="saveLine(index)" :disabled="item.audit_status==1" /></td>
        <td><input type="text" placeholder="" class="input tc" v-model="item.quality" @change="saveLine(index)" :disabled="item.audit_status==1" /></td>
        <td><input type="text" placeholder="" class="input tc" v-model="item.quality_remark" @change="saveLine(index)" :disabled="item.audit_status==1" /></td>
        <td><input type="text" placeholder="" class="input tc" v-model="item.price" @change="saveLine(index)" :disabled="item.audit_status==1" /></td>
        <td class="no-border">
          <el-select v-model="item.pay_type" placeholder="" size="small" @change="saveLine(index)" clearable :disabled="item.audit_status==1">
            <el-option
                v-for="item in info.paytype_list"
                :key="item.id"
                :label="item.title"
                :value="item.id">
            </el-option>
          </el-select>
        </td>
        <td><input type="text" placeholder="" class="input tc" v-model="item.status" @change="saveLine(index)" :disabled="item.audit_status==1" /></td>
        <td><input type="text" placeholder="" class="input tc" v-model="item.remark" @change="saveLine(index)" :disabled="item.audit_status==1" /></td>
      </tr>
    </table>
    </div>
    <el-button type="primary" size="small" style="margin-top: 10px" @click="addLine(1)">添加新行</el-button>
    <el-button type="" size="small" style="margin-top: 10px" @click="goPage()">返回列表</el-button>
    <el-dialog
        title="选择产品"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        width="50%">
      <operate-tree :selParams="selParams" @callFunc="selOk"></operate-tree>
    </el-dialog>
  </div>
</template>
<script>

import OperateTree from "@/components/OperateTree";
import util from "@/utils/util"
export default {
  data() {
    return {
      info: false,
      search: {
        audit_status: '1',
        status_color: '',
        follow_people: '',
        source_people: '',
        pay_type: ''
      },
      dialogVisible: false,
      selParams: {
        time: new Date(),
        goods: []
      },
      goodsParams: {},
      colspan: 0,
      carModelList: [],
      staffList: [],
      menuList: [],
      content: []
    };
  },
  created() {
  },
  mounted() {
  },
  components: {
    OperateTree
  },
  computed: {
  },
  props: {
    isEdit: Boolean,
    data: Object,
    callFunc: Function
  },
  watch: {
    data(newVal) {
      if(newVal) {
        this.info = newVal
        this.staffList = this.info.staff_list
        this.menuList = this.info.menu_list
        this.carModelList = this.info.car_model_list
        this.colspan = 18 // this.menuList.length + 16
        this.content = this.info.content
        this.content = this.content.filter(item => {
          item.isShow = true
          if(item.audit_status==1) {
            item.isShow = false
          }
          item.status_color = item.status_color>0 ? String(item.status_color) : ''
          item.follow_people = item.follow_people>0 ? item.follow_people : ''
          item.source_people = item.source_people>0 ? item.source_people : ''
          item.is_first = item.is_first>0 ? String(item.is_first) : ''
          item.car_model_id = item.car_model_id>0 ? item.car_model_id : ''
          item.c_date = item.c_date!='0000-00-00' ? item.c_date : ''
          item.handover_date = item.handover_date!='0000-00-00' ? item.handover_date : ''
          item.pay_type = item.pay_type>0 ? item.pay_type : ''
          item.price = item.price>0 ? item.price : ''
          return item
        })
        // if(newVal.content) {
        //   this.content = newVal.content
        // } else {
        //   for(var i=0; i<5; i++) {
        //     this.content.push(JSON.parse(JSON.stringify(this.getLine(this.info.field))))
        //   }
        // }
        // console.log(this.content)
      }
    }
  },
  methods: {
    handleSearch() {
      this.content = this.content.filter(item => {
        item.isShow = true
        if(this.search.status_color && item.status_color!=this.search.status_color) {
          item.isShow = false
        }
        if(this.search.follow_people && item.follow_people!=this.search.follow_people) {
          item.isShow = false
        }
        if(this.search.source_people && item.source_people!=this.search.source_people) {
          item.isShow = false
        }
        if(this.search.pay_type && item.pay_type!=this.search.pay_type) {
          item.isShow = false
        }
        if(this.search.audit_status==1 && item.audit_status==1) {
          item.isShow = false
        }
        if(this.search.audit_status==2 && item.audit_status==0) {
          item.isShow = false
        }
        return item
      })
    },
    selectGoods(index) {
      if(this.content[index].audit_status==1) {
        return false
      }
      this.goodsParams.index = index
      this.dialogVisible = true
      this.selParams = {
        time: new Date(),
        goods: this.content[index].item
      }
      // this.goodsParams.cindex = cindex
      // if(!this.content[index].car_model_id) {
      //   this.fail('请先选择车型')
      //   return false
      // }
      // this.dialogVisible = true
      // this.selParams = {
      //   menu_id: this.info.menu_list[cindex].id,
      //   model_id: this.content[index].car_model_id
      // }
    },
    delGoods(index,cidx) {
      this.content[index].item.splice(cidx,1)
      this.countPrice(index)
    },
    selOk(goods) {
      var item = []
      if(goods && goods.length>0) {
        goods.forEach(child => {
          item.push({
            menu_id: child.menu_id,
            goods_id: child.id,
            price: child.price,
            sum: 1,
            amount: child.price,
            title: child.title,
            work_price: child.work_price,
            cost_price: child.cost_price,
          })
        })
      }
      this.content[this.goodsParams.index].item = item
      this.saveLine(this.goodsParams.index)
      this.dialogVisible = false
    },
    countPrice(index) {
      // var amount = 0
      // this.content[index].item.forEach(item => {
      //   amount = amount + parseFloat(item.price) + parseFloat(item.work_price)
      // })
      // this.content[index].price = amount.toFixed(2)
      this.saveLine(index)
    },
    addLine(num) {
      for(var i=0; i<num; i++) {
        var item = {
          id: '',
          finance_id: this.info.id,
          status_color: '',
          c_date: '',
          handover_date: '',
          follow_people: '',
          source_people: '',
          is_first: '',
          car_no: '',
          car_model_id: '',
          car_color: '',
          audit_status: 0,
          comment_1: '',
          quality: '',
          quality_remark: '',
          price: '',
          pay_type: '',
          status: '',
          remark: '',
          isShow: true,
          item: []
        }
        this.$api.operate.financeItem(item,res => {
          if(res.errcode==0) {
            item.id = res.errmsg
            this.content.push(item)
          } else {
            this.fail(res.errmsg)
          }
        })
        // this.content.push(JSON.parse(JSON.stringify(this.getLine(this.info.field))))
      }
    },
    auditItem(index) {
      this.content[index].audit_status = 1
      this.saveLine(index)
      this.handleSearch()
    },
    saveLine(index) {
      var params = JSON.parse(JSON.stringify(this.content[index]))
      params.c_date = util.formatDate(new Date(params.c_date))
      params.handover_date = util.formatDate(new Date(params.handover_date))
      this.$api.operate.financeItem(params,res => {
        if(res.errcode==0) {
          // 1
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getLine(field) {
      var txt = {};
      for(var i in field) {
        txt[i] = field[i]
        txt[i].value = ''
      }
      return txt;
    },
    inputChange(e,index,cindex) {
      this.content[index][cindex].value = e.target.value
      this.rsyncData()
    },
    rsyncData() {
      if(this.callFunc) {
        this.callFunc(this.content)
      }
    },
    goPage() {
      this.$router.push({name: 'operate_finance_box', query: {msg:'all'}})
    }
  }
};
</script>
<style scoped>
</style>
